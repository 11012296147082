import React, {useEffect} from "react";
import { Trans, useTranslation } from "react-i18next";
import { checkLanguage } from "../utils/checkLanguage.jsx";
import { googlePlayUrlDefault, appStoreEnglish, appStoreSpanish, AndroidLink, AppleLink } from "../utils/constants.jsx";

const LandingPage = () => {
  const { t } = useTranslation();
  const language = checkLanguage();
  const googlePlayUrl = language === "es-ES" ? `${googlePlayUrlDefault}=es` : `${googlePlayUrlDefault}=en`;
  const appStoreWebVersion = language === "es-ES" ? `${appStoreSpanish}` : `${appStoreEnglish}`;

  useEffect(() => {
    if(window.location.pathname == "/") return;
    if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
      window.location.href = AndroidLink;
  } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
      window.location.href = AppleLink;
  }  else {
      window.location.replace('/');
    }
  }, [])

  return (
    <>
      <div className="flex  items-end tablet:items-center  md:justify-start bg-cover text-white tablet:bg-landing_tablet bg-landing_mobile md:bg-landing_desktop w-full h-[576px] text-[28px] md:text-5.5xl p-4 pb-[31px] tablet:p-6  md:p-0">
        <div className="tablet:w-[381px] md:ml-[200px] md:w-[660px]">
          <p className="font-semibold text-center tablet:text-left">{t("landing.title")}</p>
          {/* <p className="text-[22px] md:text-2.5xl">{t("landing.subtitle")}</p> */}
          <div className="flex flex-col items-center tablet:flex-row tablet:mt-8">
            <a href={googlePlayUrl} rel="noopener" target="no_blank">
              <img
                src={require("../images/google-play.png")}
                alt="google_play"
                className="w-[190px] my-4 tablet:my-0 tablet:mr-10"
              />
            </a>
            <a href={appStoreWebVersion} rel="noopener" target="no_blank">
              <img
                src={require("../images/app-store.png")}
                alt="app_store"
                className="w-[190px]"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col tablet:flex-row items-center mx-auto w-full py-10 tablet:py-[56px] px-6 md:px-[86px] ">
        <img
          src={require("../images/landing-left.png")}
          alt="google_play"
          className="md:mr-10 w-[242.325px] h-[489px] tablet:w-[160px] tablet:h-[320px] md:w-[380px] md:h-[705.086px]"
        />
        <div className="text-neutral-800 items-center text-[18px] md:text-[22px] leading-normal tablet:my-0 tablet:mx-8 md:mx-[80px] my-8 md:my-0">
          <Trans
            components={{
              span: <span />,
              p: <p />,
            }}
          >
            <p>{t("landing.line1")}</p>
            <br />
            <p>{t("landing.line2")}</p>
            <br />
            <p>{t("landing.line3")}</p>
            <br />
            <p>{t("landing.line4")}</p>
          </Trans>
        </div>
        <img
          src={require("../images/landing-right.png")}
          alt="google_play"
          className="md:mr-10 w-[159.325px] h-[320px] md:w-[288px] md:h-[562.78px]"
        />
      </div>

      <div className="flex justify-center bg-primary py-10 px-4  md:py-[56px] md:px-[179px] text-white text-[22px] tablet:text-[28px] uppercase underline font-semibold">
        <a href="mailto:info@prodigypadelacademy.com">{t("common.contact")}</a>
      </div>
    </>
  );
};

export default LandingPage;
