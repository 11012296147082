import "./App.css";
import Layout from "./views/Layout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { checkLanguage } from "./utils/checkLanguage";
import { Helmet } from 'react-helmet';

const App = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    i18n.changeLanguage(checkLanguage());
  }, []);

  // useEffect(() => {
  //   const appValue = searchParams.get("share");
  //   if (appValue) {
  //     window.location.replace(`ppa://share.app/${appValue}`);
  //     setTimeout(() => {
  //       if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
  //         window.location.href = "https://play.google.com/store/apps/details?id=com.prodigypadelacademy.app";
  //       } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
  //         window.location.href = "https://apps.apple.com/app/prodigy-padel-academy/id6463727496";
  //       }
  //     }, 3000);
  //   }
  // }, [pathname, searchParams]);

  return (
    <I18nextProvider i18n={i18n}>
        <Helmet>
        <meta property="og:title" content="Prodigy Padel" />
        <meta property="og:url" content="https://www.prodigypadel.com" />
        <meta property="og:description" content="Tu entrenador de Padel" />
        <meta property="twitter:image" content="https://tbh2022.github.io/logo.jpg" />
        <meta property="twitter:image:width" content="300" />
        <meta property="twitter:image:height" content="300" />
        <meta property="twitter:image:type" content="image/png" />
        <meta property="whatsapp:image" content="https://tbh2022.github.io/logo.jpg" />
        <meta property="whatsapp:image:width" content="300" />
        <meta property="whatsapp:image:height" content="300" />
        <meta property="whatsapp:image:type" content="image/png" />
        <meta property="og:image" content="https://tbh2022.github.io/logo.jpg" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:site_name" content="Prodigy Padel" />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:locale:alternate" content="en_GB" />
      </Helmet>
      <Layout />
    </I18nextProvider>
  );
};

export default App;

