import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckedIcon } from "../images/checked.svg";
import { ReactComponent as InformationIcon } from "../images/information-icon.svg";
import apiFactory from "../helpers/apiFactory.js";

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitedd, setIsSubmitedd] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const isButtonDisabled = password.trim() === "" || password2.trim() === "";

  const hasNumber = (password) => /\d/.test(password);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePassword2Visibility = () => {
    setShowPassword2(!showPassword2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "password2") {
      setPassword2(value);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "",
      password2: "",
    }));
  };

  const validatePasswords = () => {
    const newErrors = {};

    if (password.length < 8) {
      newErrors.password =  t("password_recovery.error_length");
    }

    if (!hasNumber(password)) {
      newErrors.password = t("password_recovery.error_number");
    }

    if (password !== password2) {
      newErrors.password2 = t("password_recovery.error_match")
    }

    return newErrors;
  };

  const sendPasswordRequest = (password, token) => {
    const api = apiFactory();
    api
      .updateUserPassword(password, token)
      .then((response) => setIsSubmitedd(true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validatePasswords();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    sendPasswordRequest(password, token);
    setIsSubmitedd(true);
  };

  return (
    <>
      <div className="px-[24px] py-[40px] md:px-[264px] md:py-[112px] flex justify-center">
        <div className="w-full tablet:max-w-[786px] md:max-w-[900px]">
          <h1 className="text-[24px] md:text-[40px] font-semibold text-neutral-800">
           {t("password_recovery.heading")}
          </h1>
          <div className="bg-primary-500 p-[16px] rounded-[4px] tablet:mb-[32px] mb-[48px]">
            <p className="text-primary-700 text-[16px] md:text-[18px] font-semibold leading-[18.96px]">
            {t("password_recovery.subtitle")}
            </p>
          </div>
          <div className="mt-[32px]">
            <div className="px-[24px] py-[16px] md:px-[32px] md:py-[40px] rounded-[10px] border-[1px] border-[#A0BBFE]">
              {!isSubmitedd ? (
                <form onSubmit={handleSubmit}>
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block text-[#5F5F5F] caption-m-400 font-bold mb-2"
                    >
                     {t("password_recovery.password1")}
                      <span className="text-[#7499F5]">*</span>
                    </label>
                    <input
                      required
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handleInputChange}
                      placeholder=  {t("password_recovery.password_placeholder1")}
                      className="w-full h-[36.8px] bg-[#FAFAFA] text-[#9CA0AA] border border-[#EDEDEF] py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline"
                    />
                    <span
                      className="absolute right-3 top-10 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          className=" text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.596 1.846-1.587 3.446-2.892 4.669M15 12a3 3 0 01-6 0"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            d="M1.5 4.4525L2.46 3.5L15 16.04L14.0475 17L11.7375 14.69C10.875 14.975 9.96 15.125 9 15.125C5.25 15.125 2.0475 12.7925 0.75 9.5C1.2675 8.18 2.0925 7.0175 3.1425 6.095L1.5 4.4525ZM9 7.25C9.59674 7.25 10.169 7.48705 10.591 7.90901C11.0129 8.33097 11.25 8.90326 11.25 9.5C11.25 9.7625 11.205 10.0175 11.1225 10.25L8.25 7.3775C8.4825 7.295 8.7375 7.25 9 7.25ZM9 3.875C12.75 3.875 15.9525 6.2075 17.25 9.5C16.635 11.06 15.5925 12.41 14.25 13.3925L13.185 12.32C14.205 11.615 15.045 10.655 15.615 9.5C14.3775 6.98 11.82 5.375 9 5.375C8.1825 5.375 7.38 5.51 6.63 5.75L5.475 4.6025C6.555 4.1375 7.7475 3.875 9 3.875ZM2.385 9.5C3.6225 12.02 6.18 13.625 9 13.625C9.5175 13.625 10.0275 13.5725 10.5 13.4675L8.79 11.75C7.7175 11.6375 6.8625 10.7825 6.75 9.71L4.2 7.1525C3.4575 7.79 2.835 8.585 2.385 9.5Z"
                            fill="#5F5F5F"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  {errors.password && (
                    <div className="flex items-center text-red-500 mt-2 gap-[4px]">
                      <InformationIcon />
                      <p className="text-[12px]">{errors.password}</p>
                    </div>
                  )}

                  <div className="relative mt-4">
                    <label
                      htmlFor="password2"
                      className="block text-[#5F5F5F] caption-m-400 font-bold mb-2"
                    >
                       {t("password_recovery.password2")}
                      <span className="text-[#7499F5]">*</span>
                    </label>
                    <input
                      required
                      name="password2"
                      type={showPassword2 ? "text" : "password"}
                      value={password2}
                      onChange={handleInputChange}
                      placeholder=  {t("password_recovery.password_placeholder2")}
                      className="w-full h-[36.8px] bg-[#FAFAFA] text-[#9CA0AA] border border-[#EDEDEF] py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline"
                    />
                    <span
                      className="absolute right-3 top-10 cursor-pointer"
                      onClick={togglePassword2Visibility}
                    >
                      {showPassword2 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          className=" text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.596 1.846-1.587 3.446-2.892 4.669M15 12a3 3 0 01-6 0"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            d="M1.5 4.4525L2.46 3.5L15 16.04L14.0475 17L11.7375 14.69C10.875 14.975 9.96 15.125 9 15.125C5.25 15.125 2.0475 12.7925 0.75 9.5C1.2675 8.18 2.0925 7.0175 3.1425 6.095L1.5 4.4525ZM9 7.25C9.59674 7.25 10.169 7.48705 10.591 7.90901C11.0129 8.33097 11.25 8.90326 11.25 9.5C11.25 9.7625 11.205 10.0175 11.1225 10.25L8.25 7.3775C8.4825 7.295 8.7375 7.25 9 7.25ZM9 3.875C12.75 3.875 15.9525 6.2075 17.25 9.5C16.635 11.06 15.5925 12.41 14.25 13.3925L13.185 12.32C14.205 11.615 15.045 10.655 15.615 9.5C14.3775 6.98 11.82 5.375 9 5.375C8.1825 5.375 7.38 5.51 6.63 5.75L5.475 4.6025C6.555 4.1375 7.7475 3.875 9 3.875ZM2.385 9.5C3.6225 12.02 6.18 13.625 9 13.625C9.5175 13.625 10.0275 13.5725 10.5 13.4675L8.79 11.75C7.7175 11.6375 6.8625 10.7825 6.75 9.71L4.2 7.1525C3.4575 7.79 2.835 8.585 2.385 9.5Z"
                            fill="#5F5F5F"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  {errors.password2 && (
                    <div className="flex items-center text-red-500 mt-2 gap-[4px]">
                      <InformationIcon />
                      <p className="text-[12px]">{errors.password2}</p>
                    </div>
                  )}

                  <div className="mt-[16px]">
                    <button
                      disabled={isButtonDisabled}
                      className={`${
                        isButtonDisabled
                          ? "bg-neutral-100"
                          : "bg-yellow text-neutral-1000"
                      } text-neutral-700 px-[56px] py-3 w-full rounded-[100px] text-[18px] font-medium leading-none`}
                    >
                       {t("password_recovery.button")}
                    </button>
                  </div>
                </form>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <CheckedIcon className="mb-[24px]" />
                  <h3 className="mb-[8px] text-primary-700 text-[20px] md:text-[18px] font-semibold">{t("password_recovery.confirm_heading")}</h3>
                  <p className="mb-[8px] text-primary-700 text-[16px]  ">{t("password_recovery.confirm_text")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
