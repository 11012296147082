import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Trans, useSSR, useTranslation } from "react-i18next";
import { checkLanguage } from "../utils/checkLanguage.jsx";
import apiFactory from "../helpers/apiFactory.js";
import { ReactComponent as DeleteIcon } from "../images/delete-icon.svg";
import { ReactComponent as DeleteIconBlue } from "../images/delete-icon-blue.svg";

const DataSafetyConfirmation = () => {
  const { t } = useTranslation();
  const language = checkLanguage();
  const navigate = useNavigate();
  const [isSubmitedd, setIsSubmitedd] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleCancel = () => {
    navigate("/data-safety");
  };

  const sendTokenRequest = (userToken) => {
    const api = apiFactory();
    api.deleteUserAccount(userToken).then((response) => setIsSubmitedd(true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendTokenRequest(token);
  };

  return (
    <>
      <div className=" px-[24px] py-[40px] md:px-[264px] md:py-[112px] flex justify-center">
        {/* CONTENT */}
        <div className="w-full tablet:max-w-[786px] md:max-w-[900px]">
          <h1 className="text-[24px] md:text-[40px] font-semibold text-neutral-800">
          {t("data_safety_confirmation.heading")}
          </h1>
          <div className="border-b-[2px] border-[#FFF548] bg-[#FFF548] text-[#FFF548] mb-[24px]"></div>
          <h2 className="md:text-[22px] text-[28px] font-semibold text-neutral-700 mb-[32px]">
          {t("data_safety_confirmation.subtitle")}
          </h2>
          {!isSubmitedd ? (
            <div className="bg-primary-500  p-[16px] rounded-[4px] tablet:mb-[32px] mb-[48px]">
              <p className="text-primary-700 text-[16px] md:text-[18px] font-semibold leading-[18.96px]">
              {t("data_safety_confirmation.title_text")}
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="mt-[48px]">
            <div className="px-[24px] py-[16px] md:px-[96px] md:py-[40px] rounded-[10px] border-[1px] border-[#A0BBFE]">
              {!isSubmitedd ? (
                <div className="flex flex-col justify-center items-center ">
                  <DeleteIcon className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] " />
                  <p className="mt-[16px] md:mt-[24px] text-[18px] md:text-body-l-600 text-primary-700 font-semibold text-center">
                  {t("data_safety_confirmation.confirmation")}
                  </p>
                  <div className="bg-[#FFFF] py-6 sm:px-6 sm:flex  justify-center gap-4 w-full">
                    
                    <button
                      type="button"
                      className="px-[56px] py-3 w-full rounded-[100px] text-[18px] text-[#FFFF] font-medium leading-none mb-4 bg-primary-100"
                      onClick={handleCancel}
                    >
                      {t("data_safety_confirmation.cancel")}
                    </button>

                    <button
                      type="button"
                      className="px-[56px] py-3 w-full rounded-[100px] text-[18px] text-[#FF301D] font-medium border-[#FF301D] bg-[#FFFF] border-[1px] leading-none mb-4"
                      onClick={handleSubmit}
                    >
                      {t("data_safety_confirmation.delete")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center ">
                  <DeleteIconBlue className="w-[40px] h-[40px] md:w-[50px] md:h-[50px]" />
                  <p className="mt-[16px] md:mt-[24px] text-[18px] md:text-body-l-600 text-primary-700 font-semibold text-center">
                  {t("data_safety_confirmation.account_deleted")}
                  </p>
                  <p className="mt-[8px] text-[16px] md:text-[18px] text-primary-700 text-center">
                  {t("data_safety_confirmation.thanks")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-primary py-10 px-4  md:py-[56px] md:px-[179px] text-white text-[22px] tablet:text-[28px] uppercase underline font-semibold">
        <a href="mailto:info@prodigypadelacademy.com">{t("common.contact")}</a>
      </div>
    </>
  );
};

export default DataSafetyConfirmation;
