import axios from "axios";

const BASE_URL = "/api";

const apiFactory = () => {
  const requestDeleteUserAccount = async (email) => {
    try {
      const url = `https://prodigypadel-api-prod.azurewebsites.net/api/user/request/delete `;
      const response = await axios.post(
        url,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };


  const deleteUserAccount = async (userToken) => {
    try {
      const url = `https://prodigypadel-api-prod.azurewebsites.net/api/user/delete`;
      const response = await axios.post(
        url,
        { authtoken: userToken },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };


  const updateUserPassword = async (password, authtoken) => {
    try {
      const url = `https://prodigypadel-api-prod.azurewebsites.net/api/user/reset/password `;
      const response = await axios.post(
        url,
        { new_password: password, authToken:authtoken },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    requestDeleteUserAccount,
    deleteUserAccount,
    updateUserPassword
  };
};

export default apiFactory;
